<template>
  <h2
    class="text-xl text-headline font-semibold text-left truncate"
    :class="marginClass"
  >
    <slot></slot>
  </h2>
</template>

<script setup lang="ts">
import { useAttrs, computed } from "vue";
const attrs = useAttrs();
const marginClass = computed(() => {
  return (attrs as { class: string })?.class?.includes("mb-") ? "" : "mb-8";
});
</script>
